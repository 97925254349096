import { omit } from 'lodash';
import { OPEN_MODAL, CLOSE_MODAL } from '../modal-actions';
import detectRoute, { POST_PAGE } from '../../../../../app/services/detect-route';
import { MODAL_TYPE_CREATE_COMMENT } from '../../../../../app/components/modals/comment-create-modal/comment-create-modal-type';

export function openedModals(state = {}, action) {
  switch (action.type) {
    case OPEN_MODAL: {
      const { type } = action.payload;
      if (state[type] && type !== MODAL_TYPE_CREATE_COMMENT) {
        return state;
      }
      return {
        ...state,
        [type]: { ...action.payload, meta: action.meta || {} },
      };
    }
    case CLOSE_MODAL: {
      return omit(state, action.payload.type);
    }
    default:
      return state;
  }
}
